import { Injectable } from '@angular/core';
import { BaseHttpService } from '../../../../services/base-http.service';

@Injectable({ providedIn: 'root' })
export class ApiService extends BaseHttpService {

  // Application
  public getApplicationsList() {
    const url = this.ipAddress + '/api/backoffice.svc/Application';
    return this.get(url, { headers: this.apiHeader });
  }

  public createApplication(body: any) {
    const url = this.ipAddress + '/api/backoffice.svc/Application/CreateNew';
    return this.post(url, body, { headers: this.apiHeader });
  }

  public editApplication(body: any, code: any) {
    const apiParams = {
      'Code': code,
    };
    const url = this.ipAddress + '/api/backoffice.svc/Application/Edit';
    return this.put(url, body, { headers: this.apiHeader, params: apiParams });
  }

  public deleteApplication(code: any) {
    const url = this.ipAddress + '/api/backoffice.svc/Application';
    const apiParams = {
      'Code': code,
    };
    return this.delete(url, { headers: this.apiHeader, params: apiParams });
  }


  // Sales Channel
  public getSalesChannel() {
    const url = this.ipAddress + '/api/backoffice.svc/SalesChannel';
    return this.get(url, { headers: this.apiHeader });
  }

  public createSalesChannel(body: any) {
    const url = this.ipAddress + '/api/backoffice.svc/SalesChannel/CreateNew';
    return this.post(url, body, { headers: this.apiHeader });
  }

  public editSalesChannel(body: any, code: any) {
    const apiParams = {
      'Code': code,
    };
    const url = this.ipAddress + '/api/backoffice.svc/SalesChannel/Edit';
    return this.put(url, body, { headers: this.apiHeader, params: apiParams });
  }

  public deleteSalesChannel(code: any) {
    const url = this.ipAddress + '/api/backoffice.svc/SalesChannel';
    const apiParams = {
      'Code': code,
    };
    return this.delete(url, { headers: this.apiHeader, params: apiParams });
  }


  // BOOKING FEE
  public getBookingFee() {
    const url = this.ipAddress + '/api/backoffice.svc/BookingFeeHeader';
    return this.get(url, { headers: this.apiHeader });
  }

  public createBookingFee(body: any) {
    const url = this.ipAddress + '/api/backoffice.svc/BookingFeeHeader/CreateNew';
    return this.post(url, body, { headers: this.apiHeader });
  }

  public editBookingFee(body: any, code: any) {
    const apiParams = {
      'Code': code,
    };
    const url = this.ipAddress + '/api/backoffice.svc/BookingFeeHeader/Edit';
    return this.put(url, body, { headers: this.apiHeader, params: apiParams });
  }

  public deleteBookingFee(code: any) {
    const url = this.ipAddress + '/api/backoffice.svc/BookingFeeHeader';
    const apiParams = {
      'Code': code,
    };
    return this.delete(url, { headers: this.apiHeader, params: apiParams });
  }


  // BOOKING FEE DETAILS
  public getBookingFeeDetails(code: any) {
    const apiParams = {
      'Code': code,
    };
    const url = this.ipAddress + '/api/backoffice.svc/BookingFeeDetail';
    return this.get(url, { headers: this.apiHeader, params: apiParams });
  }

  public updateBookingDetails(body: any) {
    const url = this.ipAddress + '/api/backoffice.svc/BookingFeeDetail/Edit';
    return this.put(url, body, { headers: this.apiHeader });
  }


  // SALES TAX
  public getSalesTax() {
    const url = this.ipAddress + '/api/backoffice.svc/SalesTax';
    return this.get(url, { headers: this.apiHeader });
  }

  public createSalesTax(body: any) {
    const url = this.ipAddress + '/api/backoffice.svc/SalesTax/CreateNew';
    return this.post(url, body, { headers: this.apiHeader });
  }

  public editSalesTax(body: any, code: any) {
    const apiParams = {
      'Code': code,
    };
    const url = this.ipAddress + '/api/backoffice.svc/SalesTax/Edit';
    return this.put(url, body, { headers: this.apiHeader, params: apiParams });
  }

  public deleteSalesTax(code: any) {
    const url = this.ipAddress + '/api/backoffice.svc/SalesTax';
    const apiParams = {
      'Code': code,
    };
    return this.delete(url, { headers: this.apiHeader, params: apiParams });
  }


  // TICKET CLASS
  public getTicketClass() {
    const url = this.ipAddress + '/api/backoffice.svc/TicketClass';
    return this.get(url, { headers: this.apiHeader });
  }

  public createTicketClass(body: any) {
    const url = this.ipAddress + '/api/backoffice.svc/TicketClass/CreateNew';
    return this.post(url, body, { headers: this.apiHeader });
  }

  public editTicketClass(body: any, code: any) {
    const apiParams = {
      'Code': code,
    };
    const url = this.ipAddress + '/api/backoffice.svc/TicketClass/Edit';
    return this.put(url, body, { headers: this.apiHeader, params: apiParams });
  }

  public deleteTicketClass(code: any) {
    const url = this.ipAddress + '/api/backoffice.svc/TicketClass';
    const apiParams = {
      'Code': code,
    };
    return this.delete(url, { headers: this.apiHeader, params: apiParams });
  }

  // TICKET TYPE
  public getTicketTypeList() {
    const url = this.ipAddress + '/api/backoffice.svc/TicketType';
    return this.get(url, { headers: this.apiHeader });
  }

  public createTicketType(body: any) {
    const url = this.ipAddress + '/api/backoffice.svc/TicketType/CreateNew';
    return this.post(url, body, { headers: this.apiHeader });
  }

  public editTicketType(body: any, code: any) {
    const apiParams = {
      'Code': code,
    };
    const url = this.ipAddress + '/api/backoffice.svc/TicketType/Edit';
    return this.put(url, body, { headers: this.apiHeader, params: apiParams });
  }

  public deleteTicketType(code: any) {
    const url = this.ipAddress + '/api/backoffice.svc/TicketType';
    const apiParams = {
      'Code': code,
    };
    return this.delete(url, { headers: this.apiHeader, params: apiParams });
  }

  // PRICE CARDS
  public getPriceGroups() {
    const url = this.ipAddress + '/api/backoffice.svc/PricingGroup';
    return this.get(url, { headers: this.apiHeader });
  }

  public getPriceTicketType(code: any) {
    const apiParams = {
      'Code': code,
    };
    const url = this.ipAddress + '/api/backoffice.svc/Price';
    return this.get(url, { headers: this.apiHeader, params: apiParams });
  }

  public createPriceGroups(body: any) {
    const url = this.ipAddress + '/api/backoffice.svc/PricingGroup/CreateNew';
    return this.post(url, body, { headers: this.apiHeader });
  }

  public editPriceGroups(body: any, code: any) {
    const apiParams = {
      'Code': code,
    };
    const url = this.ipAddress + '/api/backoffice.svc/PricingGroup/Edit';
    return this.put(url, body, { headers: this.apiHeader, params: apiParams });
  }

  public deletePriceGroups(code: any) {
    const url = this.ipAddress + '/api/backoffice.svc/PricingGroup';
    const apiParams = {
      'Code': code,
    };
    return this.delete(url, { headers: this.apiHeader, params: apiParams });
  }


  // AREA CATAGORIES
  public getAreaCatagories() {
    const apiParams = {
      'CinemaId': 'CO00000001',
    };
    const url = this.ipAddress + '/api/backoffice.svc/Screen/ScreenAreaCategory';
    return this.get(url, { headers: this.apiHeader, params: apiParams });
  }


  public getConcessionItems() {
    const url = this.ipAddress + '/api/backoffice.svc/GetItem';
    return this.get(url, { headers: this.apiHeader });
  }


}
