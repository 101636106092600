<div class="kd-numberpad">
  <div class="kd-input-field">
    <input #id type="text" placeholder="ID" (focus)="onInputFocus('id')" [attr.maxlength]="pinLength" />
    <input #pin type="password" placeholder="PIN" (focus)="onInputFocus('pin')" [attr.maxlength]="pinLength" />
  </div>
  <div class="kd-num-btn-wrapper">
    <div class="row" *ngFor="let numberRow of numberPad">
      <button *ngFor="let numberItem of numberRow" class="kd-num-btn" [class.green]="numberItem.color === 'green'"
        [class.red]="numberItem.color === 'red'" [class.zero-btn]="numberItem.text === '0'"
        (click)="itemTouched(numberItem.text)">
        <span *ngIf="numberItem.color == ''">{{numberItem.text}}</span>
        <img *ngIf="numberItem.color == 'red'" src="../../../assets/images/close.svg" alt="clear_icon" />
        <img *ngIf="numberItem.color == 'green'" src="../../../assets/images/login.svg" alt="login_icon" />
      </button>
    </div>
  </div>
</div>
