import { Injectable } from "@angular/core";
import { fromEvent, Subject } from "rxjs";

@Injectable({ providedIn: 'root' })
export class IdleService {
    public idle$: Subject<boolean> = new Subject();
    public wake$: Subject<boolean> = new Subject();
    private isIdle: boolean = false;
    private idleAfterMinutes: number = 5;
    private countDown: any;

    constructor() {
        fromEvent(document, 'click').subscribe(() => this.onInteraction());
        fromEvent(document, 'wheel').subscribe(() => this.onInteraction());
        fromEvent(document, 'scroll').subscribe(() => this.onInteraction());
        fromEvent(document, 'mousemove').subscribe(() => this.onInteraction());
        fromEvent(document, 'touchstart').subscribe(() => this.onInteraction());
        fromEvent(document, 'keydown').subscribe(() => this.onInteraction());
        fromEvent(window, 'resize').subscribe(() => this.onInteraction());
        fromEvent(window, 'scroll').subscribe(() => this.onInteraction());
        fromEvent(window, 'mousemove').subscribe(() => this.onInteraction());
    }

    private onInteraction(): void {
        // Is idle and interacting, emit Wake
        if (this.isIdle) {
            this.isIdle = false;
            this.wake$.next(true);
        }

        // User interaction, reset start-idle-timer
        clearTimeout(this.countDown);
        this.countDown = setTimeout(() => {
            this.isIdle = true;
            this.idle$.next(true);
        }, 1000 * 60 * this.idleAfterMinutes);
    }
}