import { Injectable } from '@angular/core';
import { BaseHttpService } from '../../services/base-http.service';

@Injectable({ providedIn: 'root' })
export class AuthService extends BaseHttpService {

  private authKeyString = 'BO_AUTH_KEY';

  // Method to get the user details
  public getUserDetails(): Record<string, any> {
    return JSON.parse(localStorage.getItem(this.authKeyString));
  }

  // Method to set the user details
  public setUserDetails(details: Record<string, any>): void {
    localStorage.clear();
    localStorage.setItem(this.authKeyString, JSON.stringify(details));
  }

  // Method to remove the user details
  public resetUserDetails(): void {
    localStorage.removeItem(this.authKeyString);
  }

  public login(body: any) {
    const url = this.ipAddress + '/api/identity.svc/user/login';
    return this.post(url, body, { headers: this.apiHeader });
  }

  public logout() {
    const userData = this.getUserDetails();
    const url = `${this.ipAddress}/api/identity.svc/user/logout?UserId=${userData?.UserId}`;
    return this.post(url, {});
  }

}
