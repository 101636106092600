import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth/auth.service';

@Component({
  selector: 'ngx-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements AfterViewInit {

  @ViewChild('id') id: ElementRef<HTMLInputElement>;
  @ViewChild('pin') pin: ElementRef<HTMLInputElement>;

  constructor(private router: Router, private authService: AuthService) { }

  public ngAfterViewInit(): void {
    this.id.nativeElement.focus();
  }

  public itemTouched(itemText: string): void {
    if (itemText === 'clear') {
      this.id.nativeElement.value = '';
      this.id.nativeElement.classList.remove('error');
      this.pin.nativeElement.value = '';
      this.pin.nativeElement.classList.remove('error');
      this.id.nativeElement.focus();
    } else if (itemText === 'login') {
      const response: Record<string, any> = {
        UserName: this.id.nativeElement.value,
        Password: this.pin.nativeElement.value,
      };
      if (response.UserName.trim() === '') {
        this.id.nativeElement.classList.add('error');
      }
      if (response.Password.trim() === '') {
        this.pin.nativeElement.classList.add('error');
      }
      if (response.UserName.trim() !== '' && response.Password.trim() !== '') {
        this.authService.login(response).subscribe((loginResponse: Record<string, any>) => {
          if (loginResponse?.data) {
            this.authService.setUserDetails(loginResponse.data);
            this.setSession(loginResponse.data);
            this.router.navigate(['/pages/home']);
          } else {
            this.id.nativeElement.classList.add('error');
            this.pin.nativeElement.classList.add('error');
          }
        });
      }
    }
  }

  private setSession(sessionData: Record<string, any>): void {
    localStorage.setItem('access_token', sessionData.Token);
    localStorage.setItem('refresh_token', sessionData.RefreshToken);
  }

}
