import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { AuthService } from 'src/app/services/auth/auth.service';
import { IdleService } from 'src/app/services/idle.service';

@Component({
  selector: 'ngx-one-column-layout',
  styleUrls: ['./one-column.layout.scss'],
  providers: [IdleService],
  template: `
    <nb-layout windowMode>
      <nb-layout-header fixed>
        <ngx-header></ngx-header>
      </nb-layout-header>
      <nb-sidebar class="menu-sidebar" tag="menu-sidebar" responsive>
        <ng-content select="nb-menu"></ng-content>
      </nb-sidebar>
      <nb-layout-column>
        <ng-content select="router-outlet"></ng-content>
      </nb-layout-column>
    </nb-layout>
    <ng-template #nbAlert let-data let-ref="nbAlertRef">
      <nb-card class="nb-alert-card" status="primary">
        <nb-card-header class="nb-alert-card-header">Alert</nb-card-header>
        <nb-card-body class="nb-alert-card-content">Session has been expired due to system has been idle for more than 5 minutes. Please login again.</nb-card-body>
        <nb-card-footer class="nb-alert-card-footer">
          <div class="nb-dialog-btns">
            <button type="reset" nbButton size="small" status="basic" (click)="onAlertClose()">Login</button>
          </div>
        </nb-card-footer>
      </nb-card>
    </ng-template>
  `,
})
export class OneColumnLayoutComponent implements OnInit, OnDestroy {
  @ViewChild('nbAlert') nbDialog: TemplateRef<any>;

  public nbDialogRef: NbDialogRef<any>;

  constructor(private nbDialogService: NbDialogService, private idleService: IdleService, private router: Router, private authService: AuthService) { }

  public ngOnInit() {
    this.idleService.idle$.subscribe(() => this.nbDialogRef = this.nbDialogService.open(this.nbDialog));
  }

  public ngOnDestroy(): void {
    this.idleService.idle$.unsubscribe();
  }

  public onAlertClose(): void {
    this.nbDialogRef.close();
    this.authService.logout().subscribe((response: Record<string, any>) => {
      this.authService.resetUserDetails();
      this.router.navigate(['login']);
    });
  }
}
