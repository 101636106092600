import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse, HttpEvent, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { NbToastrService } from '@nebular/theme';
import { throwError, Observable, BehaviorSubject } from 'rxjs';
import { catchError, finalize, filter, take, switchMap, map } from 'rxjs/operators';
import { BaseHttpService } from './base-http.service';
import { LoaderService } from './loader.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  private refreshTokenInProgress = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(public auth: BaseHttpService, private router: Router, private toastrService: NbToastrService, private _loader: LoaderService) { }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    this._loader.setLoading(true, request.url);
    request = this.addAuthenticationToken(request);
    return next.handle(request).pipe(catchError((error: HttpErrorResponse) => {
      if (error?.status === 401) {
        if (this.refreshTokenInProgress) {
          return this.refreshTokenSubject.pipe(
            filter(result => result !== null),
            take(1),
            switchMap(() => next.handle(this.addAuthenticationToken(request))));
        } else {
          this.refreshTokenInProgress = true;
          this.refreshTokenSubject.next(null);
          return this.refreshAccessToken().pipe(
            catchError(() => {
              this.sessionExpired();
              return next.handle(this.addAuthenticationToken(request));
            }),
            switchMap((response: any) => {
              if (response?.success) {
                localStorage.setItem('access_token', response.data.Token);
                this.refreshTokenSubject.next(true);
                return next.handle(this.addAuthenticationToken(request));
              } else {
                this.sessionExpired();
                return next.handle(this.addAuthenticationToken(request));
              }
            }),
            finalize(() => this.refreshTokenInProgress = false));
        }
      } else {
        this._loader.setLoading(false, request.url);
        this.toastrService.danger('Something went wrong...', 'Error');
        return throwError(error);
      }
    })).pipe(map<HttpEvent<any>, any>((evt: HttpEvent<any>) => {
      if (evt instanceof HttpResponse) {
        this._loader.setLoading(false, request.url);
      }
      return evt;
    }));
  }

  private sessionExpired() {
    localStorage.removeItem('BO_AUTH_KEY');
    this.router.navigate(['login']);
  }

  private refreshAccessToken(): Observable<any> {
    const body = { 'Token': localStorage.getItem('access_token'), 'RefreshToken': localStorage.getItem('refresh_token') };
    return this.auth.httpClient.post(this.auth.ipAddress + '/api/identity.svc/user/RefreshToken', body);
  }

  private addAuthenticationToken(request: HttpRequest<any>): HttpRequest<any> {
    if (!request.url.match(this.auth.ipAddress)) {
      return request;
    }
    if (localStorage.getItem('access_token')) {
      return request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + localStorage.getItem('access_token')) });
    } else {
      return request;
    }
  }
}
