import { Component, OnDestroy, AfterViewInit, Output, EventEmitter, ElementRef } from '@angular/core';
import { LocationStrategy } from '@angular/common';

@Component({
  selector: 'ngx-tiny-mce',
  template: '',
})
export class TinyMCEComponent implements OnDestroy, AfterViewInit {

  @Output() editorKeyup = new EventEmitter<any>();

  public editor: any;
  tinymce: any;

  constructor(private host: ElementRef, private locationStrategy: LocationStrategy) { }

  public ngAfterViewInit() {
    this.tinymce.init({
      target: this.host.nativeElement,
      plugins: ['link', 'paste', 'table'],
      skin_url: `${this.locationStrategy.getBaseHref()}assets/skins/lightgray`,
      setup: (editor: any) => {
        this.editor = editor;
        editor.on('keyup', () => {
 this.editorKeyup.emit(editor.getContent()); 
});
      }, height: '320',
    });
  }

  public ngOnDestroy() {
    this.tinymce.remove(this.editor);
  }

}
