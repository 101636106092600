import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LoaderService {
  public loadingSub: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public loadingMap: Map<string, boolean> = new Map<string, boolean>();

  public setLoading(loading: boolean, url: string): void {
    if (!url) {
      throw new Error('The request URL must be provided to the LoadingService.setLoading function');
    }
    if (url.indexOf('logout') > 0) {
      if (loading) {
        this.loadingMap.set(url, loading);
        this.loadingSub.next(true);
      } else if (!loading && this.loadingMap.has(url)) {
        this.loadingMap.delete(url);
      }
      if (this.loadingMap.size === 0) {
        this.loadingSub.next(false);
      }
    }
  }
}