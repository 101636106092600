import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';

@Injectable({ providedIn: 'root' })
export class HomeGuard implements CanActivate {

  constructor(private auth: AuthService, private router: Router) { }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const user = this.auth.getUserDetails();
    if (user) {
      return true;
    } else {
      this.router.navigate(['login']);
      return false;
    }
  }

}
