import { Component, OnInit } from '@angular/core';
import { NbIconLibraries } from '@nebular/theme';
import { delay } from 'rxjs/operators';
import { LoaderService } from './services/loader.service';

@Component({
  selector: 'ngx-app',
  template: `
  <router-outlet></router-outlet>
  <div class="overlay" [class.show]="isLoader">
    <div class="spinner">
      <img class="kd-progress" src="../assets/kd-progress.svg" alt="loader" />
    </div>
  </div>
  `,
})
export class AppComponent implements OnInit {
  public isLoader: boolean = false;

  constructor(private iconLibraries: NbIconLibraries, private _loader: LoaderService) {
    this.iconLibraries.registerFontPack('font-awesome', { iconClassPrefix: 'fa', packClass: 'fa' });
  }

  public ngOnInit(): void {
    this._loader.loadingSub
      .pipe(delay(0))
      .subscribe((loading: any) => this.isLoader = loading);
  }
}
