import { Component, ElementRef, AfterViewInit, Output, ViewChild, EventEmitter, Input } from '@angular/core';

interface NumberItem {
  text: string;
  role: string;
  color: string;
}

export interface NumberPadResponse {
  id: string;
  pin: string;
}

@Component({
  selector: 'ngx-numberpad',
  templateUrl: './numberpad.component.html',
  styleUrls: ['./numberpad.component.scss'],
})
export class NumberpadComponent implements AfterViewInit {

  public numberPad: Array<Array<NumberItem>>;
  public activeElement: ElementRef<HTMLInputElement>;

  @ViewChild('id') id: ElementRef<HTMLInputElement>;
  @ViewChild('pin') pin: ElementRef<HTMLInputElement>;

  /**
   * Accepts the PIN length
   *
   * @default 4
   */
  @Input() pinLength: number = 4;

  /**
   * Emit when confirm clicked
   *
   * @event
   */
  @Output() confirmed = new EventEmitter<NumberPadResponse>();

  constructor() {
    this.numberPad = [
      [
        { text: '7', role: 'number', color: '' },
        { text: '8', role: 'number', color: '' },
        { text: '9', role: 'number', color: '' },
      ],
      [
        { text: '4', role: 'number', color: '' },
        { text: '5', role: 'number', color: '' },
        { text: '6', role: 'number', color: '' },
      ],
      [
        { text: '1', role: 'number', color: '' },
        { text: '2', role: 'number', color: '' },
        { text: '3', role: 'number', color: '' },
      ],
      [
        { text: 'clear', role: 'number', color: 'red' },
        { text: '0', role: 'number', color: '' },
        { text: 'login', role: 'number', color: 'green' },
      ],
    ];
  }

  public ngAfterViewInit(): void {
    this.activeElement = this.id;
    this.activeElement.nativeElement.focus();
  }

  public itemTouched(itemText: string): void {
    if (itemText === 'clear') {
      this.id.nativeElement.value = '';
      this.id.nativeElement.classList.remove('error');
      this.pin.nativeElement.value = '';
      this.pin.nativeElement.classList.remove('error');
      this.id.nativeElement.focus();
    } else if (itemText === 'login') {
      const response: NumberPadResponse = {
        id: this.id.nativeElement.value,
        pin: this.pin.nativeElement.value,
      };
      if (response.id.trim() !== '2753' || response.pin.trim() !== '2753') {
        if (response.id.trim() !== '2753') {
          this.id.nativeElement.classList.add('error');
        }
        if (response.pin.trim() !== '2753') {
          this.pin.nativeElement.classList.add('error');
        }
      }
      if (response.id.trim() === '2753' && response.pin.trim() === '2753') {
        this.confirmed.emit(response);
      }
    } else {
      let text: string = this.activeElement.nativeElement.value;
      if (text.length < this.pinLength) {
        text += itemText;
        this.activeElement.nativeElement.value = text;
      }
      if (text.length === this.pinLength) {
        this.activeElement.nativeElement.classList.remove('error');
        if (this.activeElement === this.id) {
          this.activeElement = this.pin;
          this.activeElement.nativeElement.focus();
        }
      }
    }
  }

  public onInputFocus(elementId: string): void {
    this.activeElement = (elementId === 'id') ? this.id : this.pin;
  }

}
