import { Injectable } from '@angular/core';
import { ApiService } from '../pages/box-office/pricing/api/api.service';

@Injectable({ providedIn: 'root' })
export class DataService {

  public SalesChannels: any[] = [];
  public BookingFee: any[] = [];
  public SalesTaxes: any[] = [];

  constructor(private apiService: ApiService) { }

}
