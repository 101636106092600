<div #wrapper class="k-popup-wrapper">
  <div class="k-popup-content">
    <div class="k-header">
      <h2>Confirmation</h2>
      <button (click)="cancelClicked()">
        <img src="../../../assets/images/close-dark.svg" alt="logo" />
      </button>
    </div>
    <div class="k-items">
      <div class="k-item" *ngFor="let item of selectedItems; let i = index">
        <h3>{{ item.name }}</h3>
        <div class="k-count-wrapper">
          <button class="k-center-elements" (click)="changeItemQuantity(i, 'decrement')">-</button>
          <h3>{{ item.quantity }}</h3>
          <button class="k-center-elements" (click)="changeItemQuantity(i, 'increment')">+</button>
        </div>
      </div>
    </div>
    <button class="k-confirm-btn" (click)="confirmClicked()">Confirm ₹{{ total }}</button>
  </div>
</div>
