import { AfterContentChecked, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

export interface SelectedConcessionItem {
  id: number;
  name: string;
  quantity: number;
  totalCost: number;
  baseCost: number;
}

@Component({
  selector: 'ngx-confirm-popup',
  templateUrl: './confirm-popup.component.html',
  styleUrls: ['./confirm-popup.component.scss'],
})
export class ConfirmPopupComponent implements AfterContentChecked {

  @ViewChild('wrapper') wrapper: ElementRef<HTMLElement>;

  /**
   * Display Flag
   */
  @Input() shouldShow = true;

  /**
   * Item data
   */
  @Input() selectedItems: SelectedConcessionItem[];

  /**
   * Total of the selected items
   */
  @Input() total: number;

  /**
   * Emit on cancel clicked
   * @event
   */
  @Output() cancelled: EventEmitter<void> = new EventEmitter<void>();

  /**
   * Emit on confirm clicked
   * @event
   */
  @Output() confirmed: EventEmitter<SelectedConcessionItem[]> = new EventEmitter<SelectedConcessionItem[]>();

  constructor() { }

  public ngAfterContentChecked(): void {
    if (this.shouldShow) {
      this.wrapper.nativeElement.classList.add('k-active');
    } else {
      this.wrapper.nativeElement.classList.remove('k-active');
    }
  }

  public changeItemQuantity(index: number, status: 'increment' | 'decrement') {
    const selectedItem = this.selectedItems[index];
    if (status === 'increment') {
      selectedItem.quantity = selectedItem.quantity + 1;
    } else if (status === 'decrement') {
      selectedItem.quantity = selectedItem.quantity - 1;
    }
    if (selectedItem.quantity !== 0) {
      selectedItem.totalCost = selectedItem.quantity * selectedItem.baseCost;
      this.selectedItems[index] = selectedItem;
      this.calculateTotal();
    } else {
      this.selectedItems = this.selectedItems.filter((element) => element.id !== selectedItem.id);
      this.calculateTotal();
    }
  }

  public calculateTotal(): void {
    let total = 0;
    this.selectedItems.forEach((element) => {
 total += element.totalCost; 
});
    this.total = total;
  }

  public cancelClicked(): void {
    this.shouldShow = false;
    this.cancelled.emit();
  }

  public confirmClicked(): void {
    this.shouldShow = false;
    this.confirmed.emit(this.selectedItems);
  }

}
