<div class="p-0 h-100">
  <div class="d-flex justify-content-center align-items-center h-100">
    <div class="kd-login-container">
      <div class="row d-flex w-100 p-4 m-0">
        <div class="col-sm-8 kd-numberpad p-5 m-auto">
          <div class="row g-0">
            <div class="text-center">
              <img class="img-fluid viztix-logo" src="../../../assets/images/viztiz-logo.jpg" alt="Viztix logo" />
            </div>
          </div>
          <div class="row g-0">
            <div class="text-center text-white p-3">
              <h3 class="fw-bold text-white">Backoffice Admin</h3>
            </div>
          </div>
          <div class="row g-0 px-4">
            <div class="text-center py-2">
              <input #id type="text" name="username" placeholder="Enter Username" />
            </div>
            <div class="text-center py-2">
              <input #pin type="password" name="password" placeholder="Enter Password" />
            </div>
          </div>
          <div class="row g-0">
            <div class="col-6 py-3">
              <button class="red" (click)="itemTouched('clear')">
                <img class="img-fluid" src="../../../assets/images/close.svg" alt="clear_icon" />
              </button>
            </div>
            <div class="col-6 py-3">
              <button class="green" (click)="itemTouched('login')">
                <img class="img-fluid" src="../../../assets/images/login.svg" alt="login_icon" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
