import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'ngx-search-input',
  styleUrls: ['./search-input.component.scss'],
  template: `
    <i class="control-icon ion ion-ios-search" (click)="showInput()"></i>
    <input #input placeholder="Type your search request here..."
      [class.hidden]="!isInputShown" (blur)="hideInput()" (input)="onInput($event)" />
  `,
})
export class SearchInputComponent {
  @ViewChild('input', { static: true }) input: ElementRef;

  @Output() search: EventEmitter<Event> = new EventEmitter<Event>();

  public isInputShown: boolean = false;

  public showInput(): void {
    this.isInputShown = true;
    this.input.nativeElement.focus();
  }

  public hideInput(): void {
    this.isInputShown = false;
  }

  public onInput(val: Event): void {
    this.search.emit(val);
  }

}
