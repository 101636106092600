import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CoreModule } from './@core/core.module';
import { ThemeModule } from './@theme/theme.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import {
  NbDatepickerModule, NbDialogModule, NbMenuModule, NbSidebarModule, NbToastrModule,
  NbWindowModule, NbCardModule, NbIconModule, NbButtonModule, NbSelectModule, NbFormFieldModule,
  NbInputModule, NbTabsetModule, NbListModule, NbLayoutModule, NbSpinnerModule, NbAccordionModule,
} from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginComponent } from './pages/login/login.component';
import { BaseHttpService } from './services/base-http.service';
import { ListViewAllModule } from '@syncfusion/ej2-angular-lists';
import { DropDownListModule, ListBoxAllModule } from '@syncfusion/ej2-angular-dropdowns';
import { SidebarAllModule, MenuAllModule, TreeViewAllModule } from '@syncfusion/ej2-angular-navigations';
import { RadioButtonModule, ButtonModule, CheckBoxModule } from '@syncfusion/ej2-angular-buttons';
import { DashboardLayoutModule } from '@syncfusion/ej2-angular-layouts';
import { DiagramAllModule, OverviewAllModule, UndoRedoService } from '@syncfusion/ej2-angular-diagrams';
import { ToolbarModule } from '@syncfusion/ej2-angular-navigations';
import { DropDownButtonModule, ProgressButtonModule } from '@syncfusion/ej2-angular-splitbuttons';
import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { TextBoxModule, NumericTextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { ColorPickerModule } from '@syncfusion/ej2-angular-inputs';
import { MenuModule } from '@syncfusion/ej2-angular-navigations';
import { ToastAllModule } from '@syncfusion/ej2-angular-notifications';
import { SwitchModule, ButtonAllModule } from '@syncfusion/ej2-angular-buttons';
import { DateTimePickerModule, TimePickerModule } from '@syncfusion/ej2-angular-calendars';
import { DropDownListAllModule, MultiSelectAllModule } from '@syncfusion/ej2-angular-dropdowns';
import { ScheduleModule } from '@syncfusion/ej2-angular-schedule';
import { TextBoxAllModule, UploaderModule, SliderModule } from '@syncfusion/ej2-angular-inputs';
import { AuthService } from './services/auth/auth.service';
import { AccordionModule } from '@syncfusion/ej2-angular-navigations';
import { NumberpadComponent } from './components/numberpad/numberpad.component';
import { ConfirmPopupComponent } from './components/confirm-popup/confirm-popup.component';
import { AuthInterceptor } from './services/base-interceptor';
import { DataService } from './services/data.service';
import { LoaderService } from './services/loader.service';

@NgModule({
  declarations: [AppComponent, LoginComponent, NumberpadComponent, ConfirmPopupComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    AccordionModule,
    NbAccordionModule,
    CoreModule.forRoot(),
    ThemeModule.forRoot(),
    NbEvaIconsModule,
    NbFormFieldModule,
    NbListModule,
    NbCardModule,
    NbIconModule,
    NbButtonModule,
    NbSelectModule,
    NbInputModule,
    NbTabsetModule,
    NbLayoutModule,
    NbSpinnerModule,
    NbSidebarModule.forRoot(),
    NbMenuModule.forRoot(),
    NbDatepickerModule.forRoot(),
    NbDialogModule.forRoot(),
    NbWindowModule.forRoot(),
    NbToastrModule.forRoot(),

    // SYNCFUSION
    SidebarAllModule,
    RadioButtonModule, CheckBoxModule,
    MenuAllModule,
    DropDownListModule,
    ButtonModule,
    TreeViewAllModule,
    ListViewAllModule,
    DashboardLayoutModule,
    DiagramAllModule, OverviewAllModule,
    ListBoxAllModule,
    DialogModule,
    ToolbarModule, DropDownButtonModule, ProgressButtonModule,
    TextBoxModule, NumericTextBoxModule,
    ColorPickerModule,
    ScheduleModule,
    ToolbarModule,
    SwitchModule,
    UploaderModule,
    DropDownListAllModule,
    MultiSelectAllModule,
    TextBoxAllModule,
    ButtonAllModule,
    TimePickerModule,
    MenuModule,
    DialogModule,
    DateTimePickerModule,
    SliderModule,
    ToastAllModule,
  ],
  providers: [UndoRedoService, HttpClientModule, BaseHttpService, AuthService, DataService, LoaderService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }
