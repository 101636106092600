import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class BaseHttpService {

  public ipAddress: string = environment.apiUrl;
  public apiHeader: any;

  constructor(public httpClient: HttpClient) {
    this.apiHeader = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'connectapitoken': environment.connectapitoken,
    };
  }

  // LOCAL JSON DATA
  protected getJsonFromPath(loc: string) {
    return this.httpClient.get(`assets/json/${loc}.json`);
  }

  // HTTP REQUESTS
  public get(url: string, options?: any) {
    return this.httpClient.get(url, options).pipe(catchError(this.handleError));
  }

  public post(url: string, body: any, options?: any) {
    return this.httpClient.post(url, JSON.stringify(body), options).pipe(catchError(this.handleError));
  }

  public put(url: string, body: any, options?: any) {
    return this.httpClient.put(url, JSON.stringify(body), options).pipe(catchError(this.handleError));
  }

  public delete(url: string, options?: any) {
    return this.httpClient.delete(url, options).pipe(catchError(this.handleError));
  }

  public handleError(error: HttpErrorResponse) {
    if (error.status === 401) {
      return throwError('Session Expired');
    } else {
      return throwError('Something error occurred, please try again later.');
    }
  }

}
